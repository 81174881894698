@import url(https://fonts.googleapis.com/css2?family=Cuprum&family=Kanit:wght@400;800&family=Kavoon&family=Vujahday+Script&display=swap);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Cuprum', 'Kanit', 'Kavoon', 'Vujahday Script', sans-serif;
}

:root {
    --cognac: #961614;
    --red-herring: #f22333; 
    --what-really-happened-white: #f6f6f5;
    --dice-white: #fcfffb;
    --secret-passage-cyan: #12CFF8;
    --hasbro-blue: #0988d1;
    --flam-flames: #ed991c;
    --kingdom-of-heaven-gold: #fada3c;
    --jolly-good-green: #08C46D;
    --mr-green: #018c4c;
    --with-the-rope: #A67041;
    --in-the-study: #591902;
    --j-edgar-hoover-gray: #443f40; 
    --blackmail: #0d0d0d;
    
}

body {
    background: black url(/static/media/bricks2.82dadcfd.png) center repeat;
}

.clue-todo {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 520px;
    min-height: 600px;
    background: #0d0d0d;
    background: var(--blackmail);
    text-align: center;
    margin: 125px auto;
    border-radius: 10px;
    padding-bottom: 32px;
}

h1 {
    margin: 32px 0;
    color: #f22333;
    color: var(--red-herring);
    font-family: 'Kanit', sans-serif;
    font-size: 34px;
}

.complete {
    text-decoration: line-through;
    opacity: 0.4;
}

.todo-form {
  margin-bottom: 32px;
}

.todo-input {
    padding: 14px 32px 14px 16px;
    border-radius: 4px 0 0 4px;
    border: 2px solid #A67041;
    border: 2px solid var(--with-the-rope);
    outline: none;
    width: 320px;
    background: transparent;
    color: #fcfffb;
    color: var(--dice-white);
}

.todo-input::-webkit-input-placeholder {
    color: #fcfffb;
    color: var(--dice-white);
    font-size: 1.2em;
}

.todo-input::placeholder {
    color: #fcfffb;
    color: var(--dice-white);
    font-size: 1.2em;
}

.todo-button {
    padding: 13px;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    outline: none;
    background: linear-gradient(
        90deg, 
        #961614 0%,
        #f22333 100%
    );
    background: linear-gradient(
        90deg, 
        var(--cognac) 0%,
        var(--red-herring) 100%
    );
    padding: 13px 22px;
    color: #fcfffb;
    color: var(--dice-white);
    font-size: 1.2em;
}

.todo-container {
    display: flex;
    flex-direction: row;
    position: relative;
}

.todo-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px auto;
    color: #fcfffb;
    color: var(--dice-white);
    background: linear-gradient(
        90deg,
        #0988d1 0%, 
        #12CFF8 100%
    );
    background: linear-gradient(
        90deg,
        var(--hasbro-blue) 0%, 
        var(--secret-passage-cyan) 100%
    );
    padding: 16px;
    border-radius: 5px;
    width: 90%;
}

.todo-row:nth-child(4n + 1) {
    background: linear-gradient(
        90deg,
        #018c4c 0%,
        #08C46D 100%
    );
    background: linear-gradient(
        90deg,
        var(--mr-green) 0%,
        var(--jolly-good-green) 100%
    );
}

.todo-row:nth-child(4n + 2) {
    background: linear-gradient(
        90deg,
        #ed991c 0%,
        #fada3c 100%
    );
    background: linear-gradient(
        90deg,
        var(--flam-flames) 0%,
        var(--kingdom-of-heaven-gold) 100%
    );
}

.todo-row:nth-child(4n + 3) {
    background: linear-gradient(
        90deg,
        #591902 0%,
        #A67041 100%
    );
    background: linear-gradient(
        90deg,
        var(--in-the-study) 0%,
        var(--with-the-rope) 100%
    );
}

.icons {
    display: flex;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.delete-icon {
    margin-right: 5px;
    color: #fcfffb;
    color: var(--dice-white);
}

.edit-icon {
    color: #f6f6f5;
    color: var(--what-really-happened-white);
}

